
import _ from 'underscore';

import sinceNow from './sincenow';

var Letterboxd = {

    _url: 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fletterboxd.com%2Fmaterik%2Frss',

    _isFetching: false,
    _movie: {
        date: undefined,
        title: undefined,
        year: undefined,
        rating: undefined,
        speech: undefined,
    },

    getLastSeenMovie: function(callback) {
        if (this._isFetching) {
            callback(this._movie)
        } else {
            this._isFetching = true;

            var request = new XMLHttpRequest();
            request.open('GET', this._url, true);
            request.onload = () => {
              var data = JSON.parse(request.responseText);
              console.log("getLastSeenMovie:", data)

              if (!_.has(data, 'items')) { return; }
              data = data['items'];
              if (_.isEmpty(data)) { return; }
              data = data[0];
              if (!_.has(data, 'title')) { return; }
              if (!_.has(data, 'pubDate')) { return; }

              var titleAndRating = data['title'].split(" - ");
              var rating = titleAndRating.pop();
              var titleAndYear = titleAndRating.join(" - ").split(", ");

              var date = data['pubDate'];
              var title = applyMaxLengthToString(titleAndYear[0], 40);
              var year = titleAndYear[1];

              this.setLastSeenMovie(date, title, year, rating);
              this.getLastSeenMovie(callback);
            };

            request.send();
        }
    },

    setLastSeenMovie: function(date, title, year, rating) {
        this._movie.date = date;
        this._movie.title = title;
        this._movie.year = year;
        this._movie.rating = rating;

        this._movie.speech = [
          "🍿 I watched ",
          title,
          ", from ",
          year,
          ",",
          " ",
          sinceNow(date),
          " ",
          "and gave it ",
          rating,
          ".",
        ];
    },

}

export default Letterboxd;

// ------------------------------------------------------

var applyMaxLengthToString = function(str, len) {
  var ellipses = str.length > len ? '...' : '';
  return str.substring(0, len - ellipses.length) + ellipses;
}
