
import Firebase from '../../Utils/firebase';

const DefaultSpeech = [
  "🤔",
];

var Speech = {

    _listener: undefined,
    _status: undefined,
    _speech: DefaultSpeech,

    setListener: function(listener) {
        this._listener = listener;
        this._update();
    },

    unsetListener: function() {
        this._listener = undefined;
    },

    speak: function(speech) {
        this._speech = speech || this._status || DefaultSpeech;
        this._update();
    },

    _update: function() {
        if (this._listener) {
            this._listener.onSpeech(this._speech);   
        }
        if (!this._status) {
            var self = this;
            Firebase.getStatus(function(status) {
                if (status) {
                    self._status = status;
                    self.speak(status);
                }
            })
        }
    },

}

export default Speech;
