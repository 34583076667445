import React, { Component } from 'react';
import Scroll from 'react-scroll';

import Occupations from '../Components/Occupations';
import Profile from '../Components/Profile';
import Menu from '../Components/Menu';

import './index.css';

const DefaultState = {
  type: "undefined",
}

const ValidTypes = [
  "ui-designer",
  "physicist",
  "web-developer",
  "backend-developer",
  "ios-developer",
  "tester",
  "scrum-master",
  "game-developer",
]

class App extends Component {

  constructor(props) {
    super(props);

    this.state = DefaultState;
  }

  onMouseEnter(type) {
    if (ValidTypes.indexOf(type) >= 0) {
      this.setState({type});
    } else {
      this.setState(DefaultState);
    }
  }

  onMouseLeave(type) {
    // Do nothing...
  }

  onMouseScroll(delta) {
    Scroll.animateScroll.scrollMore(delta, {
      containerId: "content",
      delay: 0,
      duration: 0,
    });
  }

  updateContentWidth(contentWidth) {
    this.setState({contentWidth})
  }

  updateContentPaddingBottom(bottom) {
    var height = window.innerHeight;
    var contentPaddingBottom = height - bottom + 8;
    this.setState({contentPaddingBottom})
  }

  updateContentPaddingTop(contentPaddingTop) {
    this.setState({contentPaddingTop})
  }

  closeContent() {
    this.setState(DefaultState);
  }

  render() {
    return (
      <div id="app" className={this.state.type}>
        <Occupations 
          type={this.state.type} 
          width={this.state.contentWidth} 
          paddingBottom={this.state.contentPaddingBottom}
          paddingTop={this.state.contentPaddingTop}
          close={ (e) => this.closeContent() } />
        <ul>
          <li className="show-desktop"><h1>My name is</h1></li>
          <li>
            <Profile />
          </li>
          <li className="show-desktop"><h1>and I'm a</h1></li>
          <li>
            <Menu
              getPositionBottom={ (e) => this.updateContentPaddingBottom(e) }
              getPositionLeft={ (e) => this.updateContentWidth(e) }
              getPositionTop={ (e) => this.updateContentPaddingTop(e) }
              onMouseEnter={ (e) => this.onMouseEnter(e) }
              onMouseLeave={ (e) => this.onMouseLeave(e) }
              onMouseScroll={ (e) => this.onMouseScroll(e) } />
          </li>
        </ul>
      </div>
    );
  }

}

export default App;
