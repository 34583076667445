import React, { Component } from 'react';

import Speech from './speech';

import './index.css';

class SpeechBubble extends Component {

  constructor(props) {
    super(props);

    this.state = { speech: [] };
  }

  componentDidMount() {
    Speech.setListener(this);
  }

  componentWillUnmount() {
    Speech.unsetListener();
  }

  onSpeech(speech) {
    this.setState({speech})
  }

  render() {
    return (
      <div id="speech-bubble">
        {
          this.state.speech.map( (value, index) => {
            if (index % 2) {
              return (<b key={index}>{value}</b>);
            } else {
              return value; 
            }
          })
        }
      </div>
    );
  }

}

export default SpeechBubble;
