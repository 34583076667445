import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SocialItem extends Component {
  render() {
    return (
      <li 
        onClick={ (e) => this.props.onClick(this.props.identifier) }
        onMouseEnter={ (e) => this.props.onMouseEnter(this.props.identifier) }
        onMouseLeave={ (e) => this.props.onMouseLeave() }>
        <i className={this.props.identifier} />
      </li>
    );
  }
}

SocialItem.propTypes = {
  identifier: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
}

export default SocialItem;
