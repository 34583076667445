import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SpeechBubble from '../../Components/SpeechBubble';

class ProfileAvatar extends Component {

  render() {
    var className = this.props.on ? "on" : "off";
    return (
      <div id="profile-avatar" className={className}>

        <div className="show-off">
          <SpeechBubble text={this.props.speech} />
          <span className="default" />
        </div>

        <div className="show-on show-desktop">
          <span className="web-developer" />
          <span className="physicist" />
          <span className="ios-developer" />
          <span className="tester" />
          <span className="ui-designer" />
          <span className="backend-developer" />
        </div>

      </div>
    );
  }

}

ProfileAvatar.propTypes = {
  on: PropTypes.bool,
  speech: PropTypes.array,
}

export default ProfileAvatar;
