
import moment from 'moment';

var sinceNow = (date) => {
    return moment(date).calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: '[This] dddd',
        sameElse: '[On the] Do [of] MMMM'
    }).toLowerCase()
};

export default sinceNow;
