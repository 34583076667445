
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get } from "firebase/database";

var app = initializeApp({
    apiKey: "AIzaSyAEfaMcX8L9QbmktHNsy2VDlOOwgglx1qg",
    authDomain: "materikab.firebaseapp.com",
    databaseURL: "https://materikab.firebaseio.com",
    projectId: "materikab",
    storageBucket: "materikab.appspot.com",
    messagingSenderId: "715414416947",
});
const db = getDatabase();

const Firebase = {

    getStatus: function(callback) {
        let statusRef = ref(db, 'status');
        return get(statusRef).then((snapshot) => {
            let status = Object.values(snapshot.val())
            console.log("getStatus:", status)
            callback(status)
        });
    },

    getOccupations: function(callback) {
        let occupationsRef = ref(db, 'occupations');
        return get(occupationsRef).then((snapshot) => {
            let occupations = snapshot.val()
            console.log("getOccupations:", occupations)
            callback(occupations)
        });
    }

}

export default Firebase;
