
import _ from 'underscore';

import sinceNow from './sincenow';

var Strava = {

    _url: 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Ffeedmyride.net%2Factivities%2F1602663',

    _isFetching: false,
    _run: {
        date: undefined,
        distance: undefined,
        time: undefined,
        pace: undefined,
        speech: undefined,
    },

    getLastRun: function(callback) {
        if (this._isFetching) {
            callback(this._run)
        } else {
            this._isFetching = true;

            var request = new XMLHttpRequest();
            request.open('GET', this._url, true);
            request.onload = () => {
              var data = JSON.parse(request.responseText);
              console.log("getLastRun:", data)

              if (!_.has(data, 'items')) { return; }
              data = data['items'];
              if (_.isEmpty(data)) { return; }
              data = data[0];
              if (!_.has(data, 'description')) { return; }
              if (!_.has(data, 'pubDate')) { return; }

              var distanceTimeAndPace = data['description'].split(", ");

              console.log(distanceTimeAndPace)

              var date = data['pubDate'];
              var distance = distanceTimeAndPace[0].replace("Run: Distance: ", "")
              var time = distanceTimeAndPace[2].replace("Moving Time: ", "")
              var pace = distanceTimeAndPace[3].replace("Pace: ", "")

              this.setLastRun(date, distance, time, pace);
              this.getLastRun(callback);
            };

            request.send();
        }
    },

    setLastRun: function(date, distance, time, pace) {
        this._run.date = date;
        this._run.distance = distance;
        this._run.time = time;
        this._run.pace = pace;

        this._run.speech = [
          "🏃‍♂️ I ran ",
          distance,
          "",
          " ",
          sinceNow(date),
          " ",
          "at ",
          pace,
          ".",
        ];
    },

}

export default Strava;
