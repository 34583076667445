
import Firebase from '../../Utils/firebase';

var Content = {

    _listener: undefined,
    _occupations: undefined,

    setListener: function(listener) {
        this._listener = listener;
        this._load();
    },

    unsetListener: function() {
        this._listener = undefined;
    },

    getOccupation: function(key) {
        if (key === "undefined") {
            return []
        }
        let occupation = this._occupations[key] || []
        console.log("Content.getOccupation:", key, occupation)
        return occupation
    },

    _load: function() {
        if (this._occupations !== undefined) {
            console.warning('Content: already loaded');
        } else {
            var self = this;
            self._occupations = {};
            Firebase.getOccupations( (occupations) => {
                if (occupations) {
                    self._occupations = occupations;
                    self._listener.onLoaded()
                }
            })
        }
    },

}

export default Content;
