import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Content from './content';
import Experience from '../../Components/Experience';

import './index.css';

class Occupations extends Component {

  componentDidMount() {
    Content.setListener(this);
  }

  componentWillUnmount() {
    Content.unsetListener();
  }

  onLoaded() {
    this.setState({})
  }

  render() {
    return (
      <div 
        id="occupations" 
        className={this.props.type} 
        style={{
          paddingBottom: this.props.paddingBottom + "px",
          paddingTop: this.props.paddingTop + "px",
          width: this.props.width + "px",
        }}>
          <i className="close" onClick={ (e) => this.props.close() } />
          <div id={this.props.type}>
            {
              Content.getOccupation(this.props.type).map( (occupation, section) => {
                return (
                  <div key={section}>
                    <h1>{occupation.header}</h1>
                    <ul>
                      {
                        occupation.experiences.map( (experience, row) => {
                          return (
                            <li key={row}>
                                <Experience
                                    title={experience.title}
                                    description={experience.description}
                                    durationValue={experience.durationValue}
                                    durationUnit={experience.durationUnit}
                                    fromMonth={experience.fromMonth}
                                    fromYear={experience.fromYear}
                                    toMonth={experience.toMonth}
                                    toYear={experience.toYear}
                                    ongoing={experience.ongoing} />
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                )
              })
            }       
          </div>
      </div>
    );
  }
}

Occupations.propTypes = {
  paddingBottom: PropTypes.number,
  paddingTop: PropTypes.number,
  type: PropTypes.string,
  width: PropTypes.number,
  close: PropTypes.func.isRequired,
}

export default Occupations;
