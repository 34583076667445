import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

class Experience extends Component {
  render() {
    return (
      <div className="experience">

        {
            this.props.ongoing > 0
            ?
                <div className="ongoing">
                    Ongoing: <span>{this.props.ongoing}</span>%
                </div>
            :
                null
        }

        <h1>{this.props.title}</h1>
        <div className="clear-both" />
        <h2>{this.props.description}</h2>
        <div className="clear-both" />

        <div className="timespan">
          <div className="duration">
            <p>
                <span>{this.props.durationValue}</span>
                {this.props.durationUnit}
            </p>
          </div>

          <div className="period">
            <p>
                {this.props.fromMonth}
                {
                  this.props.fromYear !== undefined
                  ?
                    <span>{this.props.fromYear}</span>
                  :
                    null
                }
            </p>
            
            {
              this.props.fromMonth !== undefined
              ?
                <i className="dash" />
              :
                null 
            }

            <p>
                {this.props.toMonth}
                <span>{this.props.toYear}</span>
            </p>
          </div>
        </div>

        <div className="clear-both" />
      </div>
    );
  }
}

Experience.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  durationValue: PropTypes.number.isRequired,
  durationUnit: PropTypes.string.isRequired,
  fromMonth: PropTypes.string,
  fromYear: PropTypes.number,
  toMonth: PropTypes.string.isRequired,
  toYear: PropTypes.number.isRequired,
  ongoing: PropTypes.number,
}

export default Experience;
